import React, { useEffect, useMemo, useState } from "react";
import type { Review, Profile, Photo, FetchStatus } from "@ddr/models";
import { CheckCircle } from "lucide-react";

import { API_URL, BG_DARK, BG_LIGHT } from "../config";
import { DRONES, formatDate, QUALITY_GUAGE } from "@ddr/utils";
import { publicMoAdapter } from "../storefront-client";
import ContactDialog from "../components/contact-dialog";
import { ErrorBox, NoItemsBox, ReactTextLink, toast } from "@ddr/ui";

import {
  Button,
  Card,
  Dialog,
  DialogTrigger,
  CardContent,
  CardHeader,
  CardTitle,
  Rating,
} from "@ddr/ui";

import SocialMediaIcons from "../components/socials";
import DroneLicense from "../assets/icons8-verified-96.svg";
import { getStateName } from "../data/utils";
import { getConnections } from "../data/connections";

const pilotServices = [
  {
    title: "Thermal Carcass Recovery",
    description:
      "Thermal drones are a growing tool to recover deer, bear, elk and other game animals.",
  },
  {
    title: "Herd Survey and Analysis",
    description:
      "Monitor herd health, antlered/anterless numbers, land improvements and more with a thermal drone.",
  },
  {
    title: "Pet Rescue and Recovery",
    description:
      "Pets (even small pets such as cats) as lost in rural environments can be located and rescue with thermal drones.",
  },
  {
    title: "Search and Rescue",
    description:
      "Thermal drones are a great tool for locating missing persons, or escaped livestock.",
  },
  {
    title: "Mapping",
    description:
      "Create high-resolution maps of your property or hunting land to use digitally or print.",
  },
  {
    title: "Thermal Inspection",
    description:
      "Thermal drones can be used for inspections of roofs, electric poles, solar panels and more.",
  },
];

export default function PilotProfile({ pilotHandle }: { pilotHandle: string }) {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [pilot, setPilot] = useState<Profile>();
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [reviewPhotos, setReviewPhotos] = useState<Map<string, Photo[]>>(
    new Map(),
  );
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [reviewsError, setReviewsError] = useState<string | null>(null);
  const [fetchPilotStatus, setFetchPilotStatus] =
    useState<FetchStatus>("loading");

  const handleError = (
    err: unknown,
    message?: string,
    description?: string,
  ) => {
    console.error(message, err);
    if (!message) return;
    toast({
      title: message,
      description: description ?? undefined,
      variant: "destructive",
    });
  };

  useEffect(() => {
    fetch(`${API_URL}/profiles/${pilotHandle}`)
      .then((res) => {
        if (!res.ok) {
          setFetchPilotStatus("error");
          throw new Error(`Error getting pilot ${res.status}`);
        }
        setFetchPilotStatus("success");
        return res.json();
      })
      .then((data) => {
        setPilot(data as Profile);
      })
      .catch((err) => handleError(err));
  }, [pilotHandle]);

  useEffect(() => {
    async function fetchPhotos() {
      if (!pilot?.photos) return;
      const res = await publicMoAdapter.getPhotos(pilot.photos);
      setPhotos(res);
    }
    fetchPhotos().catch(console.error);
  }, [pilot?.photos]);

  useEffect(() => {
    async function fetchPhotos() {
      if (!reviews.length) return;
      const photoToReviewMap = new Map<string, string>();
      reviews.forEach((review) => {
        review.photos?.forEach((photoId) => {
          photoToReviewMap.set(photoId, review.id);
        });
      });

      const photoIds = Array.from(photoToReviewMap.keys());
      const res = await publicMoAdapter.getPhotos(photoIds);

      const reviewPhotoMap = new Map<string, Photo[]>();
      res.forEach((photo) => {
        const reviewId = photoToReviewMap.get(photo.id);
        if (reviewId) {
          const photos = reviewPhotoMap.get(reviewId) ?? [];
          photos.push(photo);
          reviewPhotoMap.set(reviewId, photos);
        }
      });
      setReviewPhotos(reviewPhotoMap);
    }
    fetchPhotos().catch(console.error);
  }, [reviews]);

  useEffect(() => {
    async function fetchData() {
      if (!pilot?.account_handle) return;
      const res = await fetch(
        `${API_URL}/profiles/${pilot.account_handle}/reviews`,
      );
      if (!res.ok) {
        handleError(new Error(`Error getting reviews ${res.status}`));
        setReviewsError("Failed to load reviews");
        return;
      }
      const json = (await res.json()) as Review[];
      setReviewsError(null);
      setReviews(json);
    }
    fetchData().catch(console.error);
  }, [pilot?.account_handle]);

  const strippedUrl = useMemo<string>(() => {
    const website = pilot?.sites[0]?.website || "";
    return website.replace(/^(https?:\/\/)/, "");
  }, [pilot]);

  const displayState = useMemo<string>(() => {
    return getStateName(pilot?.serviceAddress.state || "") || "";
  }, [pilot]);

  const priorConnection = useMemo(() => {
    if (!pilot?.account_handle) return undefined;
    const { allConnections } = getConnections();
    return allConnections.find((c) => c.pilot === pilot.account_handle);
    // Note: conctact modal open is here so this re-runs when the modal is closed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pilot?.account_handle, contactModalOpen]);

  // TODO: add loading state skeleton with suspense
  if (!pilot && fetchPilotStatus === "error")
    return (
      <ErrorBox
        title="Pilot not found"
        description="Please double check the link is correct."
        className={`flex flex-col justify-center ${BG_LIGHT} min-h-screen`}
      />
    );
  // Better to show empty page than 404 while loading
  if (!pilot) return <div></div>;

  const pilotSite = pilot.sites[0];
  const pilotName = `${pilot.firstName} ${pilot.lastName}`;
  const logo =
    typeof pilotSite.logo === "string"
      ? null
      : pilotSite.logo?.reference?.image.url;
  const headshot =
    typeof pilot.headshot === "string"
      ? ""
      : pilot.headshot.reference?.image.url;

  const pilotDrones = DRONES.filter((drone) =>
    pilotSite.droneFleet.find((fleetDrone) => fleetDrone.name === drone.name),
  );

  return (
    // Note: f2f2f2 matches the background color of the DDR website
    <div className={`flex flex-col justify-center ${BG_LIGHT} min-h-screen`}>
      {/* Profile group */}
      <div className="mx-auto flex max-w-[1800px] flex-col items-start justify-center gap-5 px-4 pb-16 pt-4 lg:flex-row xl:gap-8">
        {/* Pilot profile card */}
        <Card className="h-full basis-5/12">
          <div className="flex h-full flex-col justify-between">
            <CardHeader>
              <div className="flex w-full flex-wrap items-center justify-center gap-10">
                <div className="basis-48">
                  <img
                    src={headshot}
                    className="h-48 w-48 rounded-full object-cover"
                  />
                </div>
                <div className="w-full">
                  <CardTitle className="flex flex-col items-baseline">
                    <h1 className="mb-2 text-xl font-bold">{pilotName}</h1>
                    <div className="mb-4 flex flex-row items-center">
                      <div className="cursor-default rounded bg-black p-2 text-sm font-bold text-white">
                        DDR Certified
                      </div>

                      <div className="mx-4 h-8 border-l border-gray-300"></div>

                      <div className="flex flex-col">
                        <div className="flex flex-row items-center gap-2">
                          <Rating rating={pilot.rating} />
                          <p className="text-lg font-bold">{pilot.rating}</p>
                        </div>
                        <span className="text-sm font-normal">
                          ({pilot.numberOfRatings} Reviews)
                        </span>
                      </div>
                    </div>

                    <div>
                      {!priorConnection ? (
                        <Dialog
                          open={contactModalOpen}
                          onOpenChange={setContactModalOpen}
                        >
                          <DialogTrigger asChild>
                            <Button variant="brand">Contact Pilot</Button>
                          </DialogTrigger>
                          <ContactDialog profile={pilot} />
                        </Dialog>
                      ) : null}

                      <div className="flex flex-col">
                        {priorConnection && pilotSite.socialMedia?.length ? (
                          <div className="mb-2 text-sm font-bold">
                            <div>Social</div>
                            <div>
                              <SocialMediaIcons
                                socialMedia={pilotSite.socialMedia}
                              />
                            </div>
                          </div>
                        ) : null}

                        <div className="flex flex-row text-sm">
                          {priorConnection && pilotSite.website ? (
                            <div className="mr-3 flex flex-col">
                              <div className="font-bold">Website</div>
                              <div className="font-normal">
                                <ReactTextLink
                                  href={pilotSite.website}
                                  text={strippedUrl}
                                  external
                                />
                              </div>
                            </div>
                          ) : null}

                          {priorConnection && pilot.phone ? (
                            <div className="flex flex-col">
                              <div className="font-bold">Phone</div>
                              <div className="font-normal">{pilot.phone}</div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </CardTitle>
                </div>
              </div>
            </CardHeader>

            <CardContent className="px-4">
              <div className="border-t border-gray-300 py-4">
                <div className="flex flex-row">
                  <p className="font-bold">Bio:</p>
                  <p className="ml-2">{pilot.about_pilot}</p>
                </div>
              </div>

              {pilotSite.companyName ? (
                <div className="flex flex-row flex-wrap-reverse justify-between gap-3 border-t border-gray-300 pt-4">
                  <div className="max-w-sm">
                    <p>
                      <span className="mr-2 font-bold">Company Name:</span>
                      {pilotSite.companyName}
                    </p>
                    {pilotSite.companyDescription ? (
                      <div className="my-2">
                        <p className="font-bold">About Company:</p>
                        <p className="mt-2">{pilotSite.companyDescription}</p>
                      </div>
                    ) : null}
                  </div>

                  {logo ? (
                    <div className="max-w-[200px]">
                      <img
                        src={logo}
                        alt={pilotSite.companyName}
                        className="h-48 w-48 rounded-md object-cover"
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}
            </CardContent>
          </div>
        </Card>

        <div className="flex max-w-[800px] basis-7/12 flex-col">
          {/* Flight hours and drone license */}
          <div className="mb-5 flex flex-row">
            {/* <div className="mr-1 w-1/2">
              <Card className="flex h-full items-center justify-center p-4 text-center">
                <div className="flex flex-col text-sm">
                  <div className="text-lg font-semibold">Flight hours</div>
                  <div>
                    <img src="" alt={`${pilot.flyingHours} of flying time`} />
                  </div>
                  <div>Verified Flight Hours: {pilot.flyingHours} </div>
                  <div>
                    <span className="mr- font-bold">Ascertained by:</span>{" "}
                  </div>
                  <div>
                    <span className="mr- font-bold">Last verified:</span>{" "}
                  </div>
                </div>
              </Card>
            </div> */}
            <div className="w-full">
              <Card className="flex h-full items-center justify-center p-4 text-center">
                <div>
                  <div className="m-auto my-4 flex items-center justify-center">
                    <img src={DroneLicense} alt="Drone License" />
                  </div>
                  <h2 className="text-xl font-semibold">
                    Commercial Drone License
                  </h2>
                  <div>{`${pilotName} has a commercial drone license and can legally offer drone services.`}</div>
                </div>
              </Card>
            </div>
          </div>

          {/* Equipment */}
          <div className="grow">
            <Card
              className={`flex h-full items-center justify-center p-4 text-center text-white ${BG_DARK}`}
            >
              <div>
                <div className="mb-8 flex flex-col items-center">
                  <h2 className="my-2 text-2xl font-bold text-white">
                    Drone and Equipment
                  </h2>
                  <p>{pilotName} is using the following equipment</p>
                </div>
                {pilotDrones.map((drone, index) => {
                  return (
                    <div
                      key={`${drone.name}-${index}`}
                      className="flex flex-col items-center"
                    >
                      <div className="my-2 flex flex-row flex-wrap justify-center gap-6 text-left">
                        <div className="basis-64">
                          <img src={drone.image} alt={drone.name} />
                        </div>

                        <div className="basis-96">
                          <h3 className="text-lg font-semibold">
                            {drone.name}
                          </h3>
                          <p className="text-sm">{drone.description}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div>
                  <div></div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>

      {/* Services */}
      <div className="mx-auto flex max-w-[1800px] flex-col items-center justify-center px-4 py-28">
        <h2 className="max-w-md text-center text-3xl font-semibold">
          {`${pilotName} is offering thermal drone services in ${displayState}`}
        </h2>
        <p className="my-4 text-center">
          Typical services offered by thermal drone pilots include:
        </p>
        <div className="flex w-full flex-wrap justify-center">
          {pilotServices.map((service, index) => (
            <div
              key={index}
              className="flex w-full flex-col items-center p-4 text-center sm:w-1/2 lg:w-1/3"
            >
              <div className="flex flex-col items-center p-4">
                <CheckCircle className="mb-2 h-8 w-8 text-gray-800" />
                <h3 className="my-2 text-xl font-bold">{service.title}</h3>
                <p className="my-2 text-gray-900">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Photos */}
      {photos.length > 0 && (
        <div className={`px-5 py-28 md:px-7 ${BG_DARK}`}>
          <div className="mx-auto flex max-w-[1800px] flex-wrap justify-between gap-2">
            {photos.map((photo, index) => (
              <img
                key={index}
                src={photo.image.url}
                alt={`Thumbnail ${index + 1}`}
                className="h-auto w-full flex-1 rounded-sm object-cover sm:w-[calc(50%-0.5rem)] md:w-[calc(33.33%-0.5rem)] lg:w-[calc(20%-0.4rem)]"
              />
            ))}
          </div>
        </div>
      )}

      {/* Reviews */}
      <div className="rounded bg-white px-5 py-28 md:px-7">
        <h2 className="mb-5 mt-8 text-center text-3xl font-semibold">
          Reviews
        </h2>
        <div className="mb-4">
          {reviewsError ? (
            <ErrorBox className="min-h-[200px]">
              <p>{reviewsError}. Please try again later.</p>
            </ErrorBox>
          ) : reviews.length === 0 ? (
            <NoItemsBox message="No reviews yet" className="min-h-[200px]" />
          ) : (
            <ul className="mx-auto flex max-w-[1800px] flex-wrap justify-center gap-4">
              {reviews.map((review) => (
                <li
                  key={review.id}
                  className="flex shrink-0 basis-[300px] flex-col items-center rounded-md bg-white p-4 text-center shadow-md"
                >
                  <div className="my-2 text-xl font-semibold">
                    {review.rating.toPrecision(2)}
                  </div>

                  <Rating rating={review.rating} />

                  {(review.photos?.length ?? 0) > 0 && (
                    <div className="mt-4 flex gap-2">
                      {reviewPhotos
                        .get(review.id)
                        ?.slice(0, 3)
                        .map((photo, index) => (
                          <img
                            key={index}
                            src={photo.image.url}
                            alt={`Review Photo ${index + 1}`}
                            className="h-16 w-16 rounded-md object-cover"
                          />
                        ))}
                    </div>
                  )}

                  <p className="my-4 max-w-xs">{review.review}</p>

                  <p className="text-muted-foreground mt-2 text-center text-sm">
                    {review.customer.reference?.name?.value ? (
                      <span>{review.customer.reference.name.value},</span>
                    ) : null}{" "}
                    {formatDate(review.date)}
                  </p>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {/* Drone level */}
      <div className="mx-auto flex max-w-[1800px] flex-col py-28 md:flex-row">
        <div className="flex w-full flex-col items-center justify-center p-8 text-center md:w-3/4">
          <h2 className="my-4 text-3xl font-semibold">Drone level: Elite</h2>
          <p className="max-w-xl">{pilotDrones[0]?.description ?? ""} </p>
        </div>
        <div className="m-auto max-w-md md:w-1/4">
          <img src={QUALITY_GUAGE} alt="gauge" />
        </div>
      </div>
    </div>
  );
}
