import type { Profile } from "@ddr/models";
import { Badge, Button, Rating } from "@ddr/ui";
import Avatar from "./avatar";

const maxBioLength = 150;

export default function ProfileListItem({
  profile,
  onViewMoreClick,
  onContactPilotClick,
}: {
  profile: Profile;
  onViewMoreClick: () => void;
  onContactPilotClick: () => void;
  existingConnection?: boolean;
}) {
  const truncatedBio = profile.about_pilot?.slice(0, maxBioLength);
  const hasMoreBio = (profile.about_pilot?.length ?? 0) > maxBioLength;

  return (
    <li key={profile.id} className="mb-5 rounded-lg bg-white p-4 pb-5 pt-5">
      <div className="mb-3 flex items-start justify-between gap-3">
        <Avatar profile={profile} useBuiltInHeader />

        <div>
          <Rating showLabel={false} size={22} rating={profile.rating}></Rating>
          <p className="flex justify-end gap-1 text-sm">
            <span className="font-bold">{profile.rating}</span>
            <span>({profile.numberOfRatings})</span>
          </p>
        </div>
      </div>

      <p className="text-muted-foreground mb-5">
        {truncatedBio}
        {hasMoreBio && "..."}
      </p>

      <div className="flex items-end justify-between gap-2">
        <div className="flex flex-wrap items-center gap-2">
          <Button size="sm" className="mr-3" onClick={onContactPilotClick}>
            Contact Pilot
          </Button>

          <Button size="sm" variant={"secondary"} onClick={onViewMoreClick}>
            View More
          </Button>
        </div>

        <Badge variant={"secondary"} className="whitespace-nowrap">
          DDR Certified
        </Badge>
      </div>
    </li>
  );
}
