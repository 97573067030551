import React, { useCallback, useState } from "react";
import type { FC } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm } from "react-hook-form";
import { type z } from "zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  toast,
  Button,
  ImageDialog,
  ActionBtn,
  Textarea,
} from "@ddr/ui";
import {
  type S3PresignedPost,
  type ComplaintFormSubmit,
  MAX_COMPLAINT_PHOTOS,
  zComplaintForm,
  type ComplaintSubmitResponse,
} from "@ddr/models";
import { API_URL } from "../config";
import { X } from "lucide-react";
import { MAX_PHOTO_SIZE } from "@ddr/utils";
import { DateTime } from "luxon";

type ReportFormValues = z.infer<typeof zComplaintForm>;
const photoInputId = "photosInput";

const FileComplaint: FC = () => {
  const [modalImage, setModalImage] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<ReportFormValues>({
    resolver: zodResolver(zComplaintForm),
    mode: "onChange",
    defaultValues: {
      photos: [],
      name: "",
      phoneNumber: "",
      phoneCode: "",
      cost: "",
      date: "",
      time: "",
      missionCode: "",
      details: "",
    },
  });

  const {
    fields: photoFields,
    append: appendPhoto,
    remove: removePhoto,
  } = useFieldArray({
    control: form.control,
    name: "photos",
  });

  const uploadToS3 = async (presignedPost: S3PresignedPost, file?: File) => {
    if (!file) return;

    const formData = new FormData();
    Object.entries(presignedPost.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("file", file);

    const uploadResponse = await fetch(presignedPost.url, {
      method: "POST",
      body: formData,
    });

    if (!uploadResponse.ok)
      throw new Error(`[${uploadResponse.status}] Failed to upload file to S3`);
  };

  const onSubmit = async (data: ReportFormValues) => {
    setIsSubmitting(true);

    const dt = DateTime.fromFormat(data.date, "yyyy-MM-dd");
    const [hour, minute] = data.time.split(":");
    const newDt = dt.set({ hour: Number(hour), minute: Number(minute) });

    const upload: ComplaintFormSubmit = {
      ...data,
      photos: data.photos.map((p) => ({ contentType: p.file.type })),
      cost: parseFloat(data.cost.replace(/[$,]/g, "")),
      date: newDt.toISO() ?? "",
    };

    const res = await fetch(`${API_URL}/complaints`, {
      method: "POST",
      body: JSON.stringify(upload),
    });
    const resData = (await res.json()) as ComplaintSubmitResponse;

    if (!res.ok) {
      toast({
        title:
          resData.message ?? "There was an error submitting your complaint",
        description: resData.description,
        variant: "destructive",
      });
      setIsSubmitting(false);
      throw new Error("Failed to submit complaint");
    }

    try {
      await Promise.all(
        resData.stagedUploads.map(async (post, i) => {
          await uploadToS3(post, photoFields[i]?.file);
        }),
      );

      toast({
        title: "We've submitted your complaint!",
        description: "We'll be in touch soon.",
      });
      setIsSubmitting(false);
      form.reset();
      // This little SOB caused a ghost bug where React error #31
      // was thrown when the contact modal was opened
      // const input = document.getElementById(photoInputId) as HTMLInputElement | null;
      // if (input) input.value = "";
    } catch (err) {
      console.error(err);
      toast({ title: "Sorry, something went wrong.", variant: "destructive" });
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files?.length) return;
    const remaining =
      MAX_COMPLAINT_PHOTOS - (files.length + photoFields.length);
    if (remaining < 1) {
      toast({
        title: "Please select fewer photos to upload",
        variant: "destructive",
      });
      return;
    }

    for (const file of files) {
      if (file.size > MAX_PHOTO_SIZE.bytes) {
        toast({
          title: `Skipped "${file.name}" because it's too large.`,
          description: `Max size is ${MAX_PHOTO_SIZE.display}.`,
          variant: "destructive",
        });
        continue;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        appendPhoto({ value: reader.result as string, file });
      };
      reader.readAsDataURL(file);
    }
  };

  const phoneField = form.getFieldState("phoneNumber");
  const isSendCodeDisabled =
    phoneField.invalid || isSendingCode || !phoneField.isTouched;

  const handleThumbnailClick = (url: string) => {
    setModalImage(url);
    setIsModalOpen(true);
  };

  const handleSendCode = useCallback((phone: string) => {
    setIsSendingCode(true);
    fetch(`${API_URL}/verify`, {
      method: "POST",
      body: JSON.stringify({ phone }),
    })
      .then((res) => {
        // API always returns 200 for security reasons
        toast({ title: "Please check your phone for a verification code" });
        if (!res.ok) throw new Error("Failed to send code");
      })
      .catch((err) => console.error(err))
      .finally(() => setIsSendingCode(false));
  }, []);

  const formatCurrency = (value: string) => {
    const numericValue = value.replace(/\D/g, "");
    const cents = parseInt(numericValue, 10);
    if (isNaN(cents) || numericValue === "") {
      return "$0.00";
    }

    const dollars = (cents / 100).toFixed(2);
    return `$${dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  return (
    <div className="flex flex-col items-center p-20">
      <div className="mb-3 flex items-center justify-between gap-3">
        <h1 className="text-2xl font-bold">File a Complaint</h1>
        {API_URL.includes("localhost") ? (
          <Button
            size="sm"
            onClick={() => {
              form.setValue("phoneNumber", "937-515-3760");
              form.setValue("name", "John Doe");
              form.setValue("phoneCode", "6437");
              form.setValue("cost", "10.00");
              form.setValue("date", "2024-08-01");
              form.setValue("time", "09:35");
              form.setValue("missionCode", "b1a3F4");
              form.setValue(
                "details",
                "The pilot was late and crashed his drone into my house",
              );
            }}
          >
            Fill Form
          </Button>
        ) : null}
      </div>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="max-w-lg space-y-8 rounded-lg p-5"
        >
          <FormField
            control={form.control}
            name="phoneNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>

                <div className="flex gap-3">
                  <FormControl>
                    <Input {...field} placeholder="123-456-7890" />
                  </FormControl>

                  <ActionBtn
                    onClick={() =>
                      handleSendCode(form.getValues("phoneNumber"))
                    }
                    type="button"
                    className="min-w-24"
                    loading={isSendingCode}
                    disabled={isSendCodeDisabled}
                  >
                    Send Code
                  </ActionBtn>
                </div>

                <FormDescription>
                  Enter the phone number used to hire the pilot. You'll need to
                  enter the code sent to your phone to verify the complaint.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel htmlFor="name">Name</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Enter your name" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="phoneCode"
            render={({ field }) => (
              <FormItem>
                <FormLabel htmlFor="phoneCode">Phone Code</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="1234" />
                </FormControl>
                <FormDescription>
                  Enter the code sent to your phone
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="cost"
            render={({ field }) => (
              <FormItem>
                <FormLabel htmlFor="cost">Cost of Transaction</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    value={formatCurrency(String(field.value))}
                    onChange={(e) => {
                      const input = e.target.value.replace(/\D/g, "");
                      const formattedValue = formatCurrency(input);
                      field.onChange(formattedValue);
                    }}
                    placeholder="Cost"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex flex-wrap justify-between gap-4">
            <div>
              <FormField
                control={form.control}
                name="date"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel htmlFor="date">Date</FormLabel>
                    <FormControl>
                      <Input type="date" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div>
              <FormField
                control={form.control}
                name="time"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel htmlFor="time">Approximate Time</FormLabel>
                    <FormControl>
                      <Input type="time" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="max-w-lg">
            <FormField
              control={form.control}
              name="details"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Details</FormLabel>
                  <FormControl>
                    <Textarea
                      rows={5}
                      placeholder={
                        "Tell us what happened - please be as specific as possible"
                      }
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="missionCode"
            render={({ field }) => (
              <FormItem>
                <FormLabel htmlFor="missionCode">
                  Mission Code (optional)
                </FormLabel>
                <FormControl>
                  <Input {...field} placeholder="b1a3F4" />
                </FormControl>
                <FormDescription>
                  If you have a mission code, enter it here. This will help us
                  find you in our system.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="photos"
            render={() => (
              <FormItem>
                <FormLabel htmlFor="otherPhotos">Upload Photos</FormLabel>
                <FormControl>
                  <Input
                    id={photoInputId}
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={(e) => handleFileChange(e)}
                  />
                </FormControl>
                <FormDescription>
                  Upload up to 12 photos with proof of misbehavior or bad
                  service. Include screenshots of text messages with the pilot
                  if applicable.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex flex-wrap gap-4">
            {photoFields.map((field, index) => (
              <div key={field.id} className="relative w-[45%]">
                <img
                  src={field.value}
                  alt={`Photo ${index + 1}`}
                  className="h-auto w-full cursor-pointer rounded-md"
                  onClick={() => handleThumbnailClick(field.value)}
                />

                <Button
                  type="button"
                  className="absolute right-3 top-3 h-auto rounded-full bg-red-500 p-1 text-white hover:bg-red-700"
                  onClick={() => removePhoto(index)}
                >
                  <X size={14} />
                </Button>
              </div>
            ))}
          </div>

          <ActionBtn
            loading={isSubmitting}
            disabled={isSubmitting || isSendingCode || !form.formState.isValid}
            className="min-w-28"
            type="submit"
          >
            Submit Complaint
          </ActionBtn>
        </form>
      </Form>

      {modalImage ? (
        <ImageDialog
          image={modalImage}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
    </div>
  );
};

export default FileComplaint;
