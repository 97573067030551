// Sentry initialization should be imported first!
import "./instrument";

import PilotProfile from "./pages/pilot-profile";
import PilotMap from "./pages/pilot-map";
import FileComplaint from "./pages/complaint-file";

import { ROOT_ELEMENT_ID } from "./config";
import { cn } from "@ddr/ui/src/utils";
import { ErrorBoundary, ReactButtonLink, Toaster } from "@ddr/ui";

function App() {
  const rootElement = document.getElementById(ROOT_ELEMENT_ID);
  const componentName = rootElement?.dataset.component;

  let component;

  if (componentName === "pilot-profile") {
    const pathParts = window.location.pathname.split("/");
    const pilotHandle = pathParts[pathParts.length - 1] ?? "";
    component = <PilotProfile pilotHandle={pilotHandle} />;
  } else if (componentName === "pilot-map") {
    component = <PilotMap />;
  } else if (componentName === "complaint-file") {
    component = <FileComplaint />;
  } else {
    component = <div>Page not found</div>;
  }

  // Full page (or widget) error
  const fullPageError = (
    <div className="flex h-[400px] items-center justify-center">
      <div className="p-5 text-center">
        <h1 className="mb-2 text-xl font-bold">Sorry, something went wrong.</h1>
        {/* TODO: report error to Sentry */}
        <p className="mb-4">Our team has been informed.</p>
        <ReactButtonLink href="/" text="Go Home" variant="brand" />
      </div>
    </div>
  );

  return (
    // Sits directly inside root element
    <div className={cn("bg-background kd-widget font-sans antialiased")}>
      <Toaster />
      <ErrorBoundary fallback={fullPageError}>{component}</ErrorBoundary>
    </div>
  );
}

export default App;
