import type {
  ListResponse,
  MetaobjectsResponse,
  MetaobjectType,
  Review,
  Photo,
  PhotoResponse,
  PhotoListResponse,
} from "@ddr/models";
import { transformMetaobject } from "./metaobject-utils";
import {
  createStorefrontApiClient,
  StorefrontApiClient,
} from "@shopify/storefront-api-client";
import { SHOPIFY_API_VERSION } from "@ddr/utils";

/** Metaobject adapter - uses Storefront API (no rate limits!) */
export class PublicMOAdapter {
  client: StorefrontApiClient;

  constructor(shopName: string, token: string) {
    this.client = createStorefrontApiClient({
      storeDomain: `${shopName}.myshopify.com`,
      apiVersion: SHOPIFY_API_VERSION,
      publicAccessToken: token,
    });
  }

  public async getPhoto(
    id: string,
    cache?: RequestCache,
  ): Promise<Photo | null> {
    const query = `
    query Node {
      node(id: "${id}") {
        id
        ... on MediaImage {
          alt
          id
          mediaContentType
          image { id height altText url }
        }
      }
    }`;

    const result = await this.client.request<PhotoResponse>(query, {
      variables: { cache },
    });

    if (!result.data?.node) return null;
    return result.data.node;
  }

  public async getPhotos(
    ids: string[],
    cache?: RequestCache,
  ): Promise<Photo[]> {
    const query = `
    query Nodes($ids: [ID!]!) {
      nodes(ids: $ids) {
        id
        ... on MediaImage {
          alt
          id
          mediaContentType
          image { id height altText url }
        }
      }
    }`;

    const result = await this.client.request<PhotoListResponse>(query, {
      variables: { cache, ids },
    });

    return (result.data?.nodes ?? []).filter((p) => p?.image);
  }

  /**
   * Get public reviews (does not include customer details or publishable status)\
   * Reviews with draft status are not accessible via this API
   */
  public async getReviews(
    first: number,
    cache?: RequestCache,
  ): Promise<ListResponse<Review>> {
    const query = `
    query MetaobjectsQuery($type: String!, $first: Int!) {
      metaobjects(type: $type, first: $first) {
        nodes {
          handle
          id
          type
          updatedAt
          fields { key type value }
          pilot: field(key: "pilot") {
            value
            type
            reference {
              ... on Metaobject {
                handle
                id
                type
                updatedAt
                name: field(key: "name") { value }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          hasPreviousPage
          startCursor
        }
      }
    }`;

    return await this.getObjects<Review>("review", first, query, cache);
  }

  public async getObjects<T>(
    type: MetaobjectType,
    first: number,
    query: string,
    cache?: RequestCache,
  ): Promise<ListResponse<T>> {
    const before = Date.now();

    // Caching
    // https://nextjs.org/docs/app/building-your-application/data-fetching/fetching-caching-and-revalidating#time-based-revalidation
    const result = await this.client.request<MetaobjectsResponse>(query, {
      variables: { type, first, cache },
    });
    console.log(
      `[PublicMOAdapter] Fetching ${type}s took ${Date.now() - before}ms`,
    );

    if (result.errors) {
      // TODO: construct better error message
      const status = result.errors.networkStatusCode;
      const message = result.errors.message;
      const gqlErrors = result.errors.graphQLErrors;
      if (gqlErrors) console.log(gqlErrors);
      throw new Error(`[${status}] ${message}`);
    }

    const transformed =
      result.data?.metaobjects.nodes.map((mo) => {
        return transformMetaobject<T>(mo);
      }) ?? [];

    return { pageInfo: result.data?.metaobjects.pageInfo, nodes: transformed };
  }
}
