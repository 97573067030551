import { type ShopName, SHOPS } from "@ddr/utils";

export const SHOP_NAME = import.meta.env.VITE_SHOP_NAME as ShopName;
export const SHOP = SHOPS[SHOP_NAME];

export const ROOT_ELEMENT_ID = "react-root-kd";
export const API_URL = `${import.meta.env.VITE_API_DOMAIN}/api`;
export const NEXT_URL = `${import.meta.env.VITE_API_DOMAIN}`;

export const PILOT_PROFILE_URL = `${SHOP.publicStorefront}/pages/pilots`;
// export const PILOT_PROFILE_URL = `${SHOP.publicStorefront}/pages/beta-pilot-profile`;

export const STOREFRONT_TOKEN = import.meta.env.VITE_STOREFRONT_TOKEN as string;
export const USE_MOCK = false; // SHOP_NAME !== "dronedeerrecovery";

// Brand color classes
export const BG_LIGHT = "bg-[#f2f2f2]";
export const BG_DARK = "bg-[#272727]";
