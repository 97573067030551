import type { Profile } from "@ddr/models";
import {
  ActionBtn,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
} from "@ddr/ui";
import React, { useState } from "react";
import { CONNECTIONS } from "@ddr/utils";
import {
  addConnection,
  getConnections,
  getPhone,
  setPhone,
} from "../data/connections";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { API_URL } from "../config";

interface Prop {
  profile: Profile;
}

const formSchema = z.object({
  phone: z
    .string({
      required_error: "Please include a phone number.",
    })
    .regex(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, "Please use format 123-456-7890"),
});
type FormValues = z.infer<typeof formSchema>;

export default function ContactDialog({ profile }: Prop) {
  const phone = getPhone();
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: { phone: phone ?? "" },
    mode: "onChange",
  });
  // Value might not be there on first render of form
  if (phone && !form.getValues("phone")) form.setValue("phone", phone);

  const { remainingConnections: remaining } = getConnections();
  const handleSubmit = () => {
    setIsSubmitting(true);
    const inputPhone = form.getValues("phone");
    if (inputPhone) setPhone(inputPhone);

    const body = { pilot: profile.account_handle, customerPhone: inputPhone };

    fetch(`${API_URL}/leads`, { method: "PUT", body: JSON.stringify(body) })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res;
      })
      .then((res) => {
        setIsSubmitting(false);
        setSuccess(
          res.status === 202
            ? "We've already sent this pilot's contact info."
            : "We've sent the info to your phone!",
        );
        addConnection(profile.account_handle);
        setTimeout(() => setSuccess(null), 3000);
      })
      .catch((err) => {
        setIsSubmitting(false);
        console.error(err);
        setError(true);
        setTimeout(() => setError(false), 3000);
      });
  };

  const remainingMsg =
    remaining <= 0
      ? "You have no connections left at this time."
      : `You have ${remaining} connection${remaining > 1 ? "s" : ""} left.`;

  return (
    <DialogContent className="w-full max-w-sm">
      <DialogHeader>
        <DialogTitle>Let's connect you!</DialogTitle>
      </DialogHeader>

      <DialogDescription>
        Enter your phone number to get {profile.firstName}'s contact info via
        text message.
      </DialogDescription>

      {remaining <= CONNECTIONS.REMAINING_WARNING && <p>{remainingMsg}</p>}

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="flex flex-col"
        >
          <div className="mt-4">
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={phone ?? "123-456-7890"}
                      type="text"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <p className="text-muted-foreground mt-2 text-xs">
            By requesting contact info you consent to receive sms from Drone
            Deer Recovery.
          </p>

          <ActionBtn
            className="mt-5"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Get {profile.firstName}'s Info
          </ActionBtn>

          {success ? (
            <p className="mt-4 text-center">{success}</p>
          ) : error ? (
            <p className="text-destructive mt-4 text-center">
              There was an error. Please try again later.
            </p>
          ) : null}
        </form>
      </Form>
    </DialogContent>
  );
}
